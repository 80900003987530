import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import AuthorizeScreen from "./pages/AuthorizeScreen";

const App: React.FC = () => (
    <Router>
        <Routes>
            <Route path="/" Component={AuthorizeScreen} />
        </Routes>
    </Router>
);

export default App;
