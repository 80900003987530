import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { Image, Flex, message} from "antd";
import SignScreen from "../components/SignScreen";

declare global {
    interface Window {
        ethereum?: any; // 或者根据你的实际情况定义更具体的类型
    }
}
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const AuthorizeScreen: React.FC = () => {
    const [signStatus, setSignStatus] = useState(false);
    const [key, setKey] = useState('');

    const query = useQuery();

    useEffect(() => {
        const keyParam = query.get('key');
        if (keyParam) {
            setKey(keyParam);
        }
    }, [query]);


    const connectToMetaMask = async (isClick:boolean) => {
        if (isClick) {
            return
        }
        if (window.ethereum) {
            try {
                // 请求用户授权
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                // 使用 web3 创建实例
                const web3 = new Web3(window.ethereum);
                // 现在可以使用 web3 进行其他操作，如获取账户信息、发送交易等
                const accounts = await web3.eth.getAccounts();
                console.log('已连接的账户:', accounts);
                if (accounts.length !== 0) {
                    setSignStatus(true)
                }
                // // 用户选择的账户
                // const selectedAccount = accounts[0];
                //
                // // 要签名的消息
                // const message = '签名此消息用于颁发您的以太坊账户VC'; // 这里替换为你想要签名的消息
                //
                // // 将消息转换为十六进制
                // const messageHex = web3.utils.utf8ToHex(message);
                //
                // // 使用账户进行消息签名
                // const signature = await window.ethereum.request({
                //     method: 'personal_sign',
                //     params: [messageHex, selectedAccount],
                // });
                //
                // // 更新状态
                // setSignature(signature);
                //
                // console.log('Key:', key);
                // console.log('Account:', selectedAccount);
                // console.log('消息:', message);
                // console.log('签名:', signature);
                //
                // // 发送请求到 API
                // await sendToApi(key, selectedAccount, signature, message);
            } catch (error) {
                message.error('连接到 MetaMask 失败', 2)
            }
        } else {
            message.warning('请先安装 MetaMask 插件', 2)
        }
    }

    const sendToApi = async (key: string, account: string, signature: string, message: string) => {
        try {
            const response = await fetch('/api/eth/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: key,
                    account: account,
                    signature: signature,
                    message: message,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('API response:', responseData);
        } catch (error) {
            console.error('发送请求到 API 失败:', error);
        }
    };

    const textArray = [
        {
            text: '在您的浏览器中，安装支持您账户所在区块链的浏览器插件钱包',
            isClick: true
        },
        {
            text: '在浏览器插件钱包中，连接到您账户所在的区块链主网络',
            isClick: true
        },
        {
            text: '在浏览器插件钱包中，登录您的区块链账户',
            isClick: true
        },
        {
            text: '单击此处以启用访问权限',
            isClick: false
        },
    ];

    return (
        <div>
            { !signStatus && <Flex style={styles.container} align={"center"} vertical>
                <Image
                    style={styles.logo}
                    preview={false}
                    src={require('../assets/logo.png')}
                />
                <span style={styles.welcome}>
                    Welcome to InCapure
                </span>
                <span style={styles.tip}>
                    为了完成区块链账户验证，请您完成以下操作
                </span>
                <Flex style={styles.step} vertical>
                    {textArray.map((item, index) => (
                        <Flex vertical>
                            <Flex align={"center"}>
                                <Flex justify={"center"} align={"center"} style={styles.number}>{index + 1}</Flex>
                                <span onClick={() => connectToMetaMask(item.isClick)} style={item.isClick ? styles.text : styles.textLast}>{item.text}</span>
                            </Flex>
                            { item.isClick && <div style={styles.line}></div> }
                        </Flex>
                    ))}
                </Flex>

            </Flex> }
            { signStatus && <SignScreen/> }
        </div>
    )
};

const styles = {
    container: {
        width: '100%',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundImage: `url(${require("../assets/banner.png")})`,
    },
    logo: {
        width: '78px',
        height: '78px',
        marginTop: 100
    },
    welcome: {
        fontWeight: 600,
        fontSize: '30px',
        color: '#303133',
        lineHeight: '42px',
        marginTop: 46
    },
    tip: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#303133',
        lineHeight: '22px',
        marginTop: 20
    },
    step: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        paddingLeft: 66,
        paddingRight: 66,
        paddingBottom: 22,
        paddingTop: 22,
        borderRadius: 10,
        marginTop: 36
    },
    number: {
        width: '22px',
        height: '22px',
        background: '#2F8DFC',
        borderRadius: '50%',
        fontWeight: 500,
        fontSize: '12px',
        color: '#FFFFFF',
        marginRight: 17
    },
    lineDiv: {
        width: '26px',
        height: '26px',
    },
    text: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#303133',
        lineHeight: '35px',
    },
    textLast: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#1572EE',
        lineHeight: '35px',
    },
    line: {
        width: '1px',
        height: '26px',
        backgroundColor: '#2F8DFC',
        marginLeft: 11
    }
}

export default AuthorizeScreen;
