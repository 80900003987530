import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { Image, Flex, message} from "antd";

declare global {
    interface Window {
        ethereum?: any; // 或者根据你的实际情况定义更具体的类型
    }
}
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const SignScreen: React.FC = () => {
    const [TipSignature, setTipSignature] = useState(false);
    const [SignSuccess, setSignSuccess] = useState(false);
    const [TipSign, setTipSign] = useState('请使用览器插件钱包对请求的消息进行签名。您可能需要单击导航栏中的图标。');
    const [key, setKey] = useState('');
    const [signatureMsg, setSignatureMsg] = useState('');
    let getDid = ''

    const query = useQuery();

    useEffect(() => {
        const keyParam = query.get('key');
        if (keyParam) {
            setKey(keyParam);
        }
    }, [query]);


    const toSign = async () => {
        setTipSignature(true)
        if (window.ethereum) {
            try {
                // 请求用户授权
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                // 使用 web3 创建实例
                const web3 = new Web3(window.ethereum);
                // 现在可以使用 web3 进行其他操作，如获取账户信息、发送交易等
                const accounts = await web3.eth.getAccounts();
                await getDidApi(key)
                console.log('已连接的账户:', accounts);

                // // 用户选择的账户
                const selectedAccount = accounts[0];

                // 获取当前时间
                const currentDate: Date = new Date();

                // 获取当前时间的各个部分
                const currentYear: number = currentDate.getFullYear();
                const currentMonth: number = currentDate.getMonth() + 1; // 月份从 0 开始，所以需要加 1
                const currentDay: number = currentDate.getDate();
                const currentHour: number = currentDate.getHours();
                const currentMinute: number = currentDate.getMinutes();
                const currentSecond: number = currentDate.getSeconds();

                const currentTime = `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinute}:${currentSecond}`

                // 要签名的消息
                const signMessage = `My wallet address is ${selectedAccount} and I want to prove wallet ownership for InCapture for DID ${getDid}.Current time: ${currentTime}`; // 这里替换为你想要签名的消息

                // 将消息转换为十六进制
                const messageHex = web3.utils.utf8ToHex(signMessage);

                // 使用账户进行消息签名
                const signature = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [messageHex, selectedAccount],
                })
                    .then((result: any) => {
                        // 处理成功的情况，result 是签名后的消息
                        setSignatureMsg(result)
                        setSignSuccess(true)
                        setTipSign(selectedAccount)
                        sendToApi(key, selectedAccount, result, signMessage);
                        message.success('签名成功', 2)
                    })
                    .catch((error: any) => {
                        // 处理失败的情况
                        console.log(error)
                        message.error('签名失败', 2)
                        setTipSignature(false)
                        setSignSuccess(false)
                    });

                console.log('Key:', key);
                console.log('Account:', selectedAccount);
                console.log('消息:', signMessage);
                console.log('签名:', signatureMsg);
                // 发送请求到 API
                // await sendToApi(key, selectedAccount, signatureMsg, signMessage);
            } catch (error) {
                message.error('连接到 MetaMask 失败', 2)
            }
        } else {
            message.warning('请先安装 MetaMask 插件', 2)
        }
    }

    const sendToApi = async (key: string, account: string, signature: string, message: string) => {
        try {
            const response = await fetch('/api/eth/callback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: key,
                    account: account,
                    signature: signature,
                    message: message,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('API did:', responseData);
        } catch (error) {
            console.error('发送请求到 API 失败:', error);
        }
    };

    const getDidApi = async (key: string) => {
        try {
            const response = await fetch('/api/eth/eth-did', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: key
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseDataDid = await response.json();
            getDid = responseDataDid.did
            console.log('API response:', responseDataDid.did);
        } catch (error) {
            console.error('发送请求到 API 失败:', error);
        }
    };

    return (
        <Flex style={styles.container} align={"center"} vertical>
                <Image
                    style={styles.logo}
                    preview={false}
                    src={require('../assets/logo.png')}
                />
                <span style={styles.welcome}>
                    验证区块链账户所有权
                </span>
                <span style={styles.tip}>
                    通过浏览器插件钱包签名，以证明区块链账户的所有权
                </span>
                { (!TipSignature && !SignSuccess) && <Flex justify={"center"} align={"center"} style={styles.signBtn} onClick={() => toSign()}>去签名</Flex> }
                { TipSignature && <Flex justify={"center"} align={"center"} style={styles.tipSign}>
                    <span>{TipSign}</span>
                </Flex> }
                { SignSuccess && <Flex justify={"center"} align={"center"} style={styles.signSuccess}>
                    <Image
                        style={styles.success}
                        preview={false}
                        src={require('../assets/success.png')}
                    />
                    <span style={styles.textSuccess}>签名成功!</span>
                </Flex> }
        </Flex>
    )
};

const styles = {
    container: {
        width: '100%',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundImage: `url(${require("../assets/banner.png")})`,
    },
    logo: {
        width: '78px',
        height: '78px',
        marginTop: 100
    },
    welcome: {
        fontWeight: 600,
        fontSize: '30px',
        color: '#303133',
        lineHeight: '42px',
        marginTop: 46
    },
    tip: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#303133',
        lineHeight: '22px',
        marginTop: 20
    },
    signBtn: {
        width: '240px',
        height: '54px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#FFFFFF',
        background: 'conic-gradient( from 0deg at -1.1102230246251565e-14% 150%, #2983FC 0%, #53CBFF 40%)',
        borderRadius: 6,
        marginTop: 114
    },
    tipSign: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#303133',
        width: '618px',
        height: '80px',
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: 10,
        marginTop: 64
    },
    signSuccess: {
        background: 'rgba(219,246,212,0.8)',
        borderRadius: 10,
        width: '618px',
        height: '80px',
        marginTop: 20
    },
    success: {
        width: '28px',
        height: '28px',
        marginRight: 14
    },
    textSuccess: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#3CC115',
    },
}

export default SignScreen;
